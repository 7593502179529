import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询合同部发布费执行额明细列表
 * @param {Object} data
 */
export function listContractUseExecuteDetail (data) {
  return request({
    url: '/ooh-statement/v1/contractExecuteFee/listContractUseExecuteDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 查询合同服务费执行额明细列表
 * @param {Object} data
 */
export function listContractServiceExecuteDetail (data) {
  return request({
    url: '/ooh-statement/v1/contractExecuteFee/listContractServiceExecuteDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}
