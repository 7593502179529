<!-- 结算单创建 -->
<template>
  <div>
    <div class="p-b-10">
      <div class="workplatform-title">合同基本信息</div>
      <Row>
        <i-col span="8">
          <span class="title">合同编号 </span>{{ detailInfo.code }}
        </i-col>
        <i-col span="8">
          <span class="title">合同甲方 </span>{{ detailInfo.firstParty }}
        </i-col>
        <i-col span="8">
          <span class="title">合同乙方 </span>{{ detailInfo.secondParty }}
        </i-col>

        <i-col span="8">
          <span class="title">合同名称 </span>{{ detailInfo.contractName }}
        </i-col>
        <i-col span="8">
          <span class="title">合同类型 </span>{{ detailInfo.typeName }}
        </i-col>
        <i-col span="8">
          <span class="title">档期时间 </span>{{ detailInfo.startDate }} -
          {{ detailInfo.endDate }}
        </i-col>
        <i-col span="8">
          <span class="title">合同销售 </span>{{ detailInfo.userName }}
        </i-col>
        <i-col span="8">
          <span class="title">审批时间 </span>{{ detailInfo.lastApprovedTime }}
        </i-col>
      </Row>
    </div>
    <div class="p-t-10">
      <Tabs v-model="type">
        <TabPane label="签约发布费" name="2">
          <template>
            <Table
              stripe
              class="m-t-5"
              :max-height="300"
              :data="signUseFee"
              :columns="column"
              :summary-method="handleSummary"
              show-summary
            ></Table>
            <div class="p-t-10">
              <div class="workplatform-title m-b-10  m-t-5">执行明细</div>
              <Row class="m-b-5">
                <i-col span="16">
                  <Row :gutter="8">

                  <i-col  span="5">
                      <DatePicker size="small"  placeholder="执行开始周期" transfer v-model="useStartDate" type="month" style="width:100%"></DatePicker>
                  </i-col>
                  <i-col  span="5">
                      <DatePicker size="small"  placeholder="执行结束周期" transfer v-model="useEndDate" type="month" style="width:100%"></DatePicker>
                  </i-col>
                    <i-col span="10">
                      <i-input
                        type="text"
                        size="small"
                        v-model="useKeyword"
                        clearable
                        placeholder="关键字"
                      ></i-input>
                    </i-col>
                    <i-col span="4">
                      <Button icon="ios-search" type="primary" size="small" @click="initUseExecuteDetail">
                        搜索
                      </Button>
                    </i-col>
                  </Row>
                </i-col>
                <i-col span="8" style="text-align: right">
                  <Select
                    style="width: 100px"
                    size="small"
                    placement="top"
                    v-model="useFeeGroupType"
                  >
                    <Option value="1">资源拆分</Option>
                    <Option value="2">单品拆分</Option>
                    <Option value="3">产品拆分</Option>
                    <Option value="4">合同汇总</Option>
                  </Select>
                </i-col>
              </Row>
              <Table
                stripe
                class="m-t-5"
                :max-height="300"
                :data="useData"
                :columns="useTableColumns"
                :summary-method="handleSummary"
                show-summary
              ></Table>
            </div>
          </template>
        </TabPane>
        <TabPane label="签约服务费" name="1">
          <template>
            <Table
              stripe
              :max-height="300"
              :data="signServiceFee"
              :columns="serviceColumn"
              :summary-method="handleSummary"
              show-summary
            ></Table>
            <div class="p-t-10">
              <div class="workplatform-title m-b-10  m-t-5">执行明细</div>
              <Row class="m-b-5">
                <i-col span="16">
                  <Row :gutter="8">

                  <i-col  span="5">
                      <DatePicker size="small" placeholder="执行开始周期" transfer v-model="serviceStartDate" type="month" style="width:100%"></DatePicker>
                  </i-col>
                  <i-col  span="5">
                      <DatePicker size="small" placeholder="执行结束周期" transfer v-model="serviceEndDate" type="month" style="width:100%"></DatePicker>
                  </i-col>
                    <i-col span="10">
                      <i-input
                        type="text"
                        size="small"
                        v-model="serviceKeyword"
                        clearable
                        placeholder="关键字"
                      ></i-input>
                    </i-col>
                    <i-col span="4">
                      <Button icon="ios-search" type="primary" size="small" @click="initServiceExecuteDetail" >
                        搜索
                      </Button>
                    </i-col>
                  </Row>
                </i-col>
                <i-col span="8" style="text-align: right">
                  <Select
                    style="width: 100px"
                    size="small"
                    placement="top"
                    v-model="serviceFeeGroupType"
                  >
                    <Option value="1">资源拆分</Option>
                    <!-- <Option value="2">单品拆分</Option>
                    <Option value="3">产品拆分</Option> -->
                    <Option value="4">合同汇总</Option>
                  </Select>
                </i-col>
              </Row>
              <Table
                stripe
                class="m-t-5"
                :max-height="300"
                :data="serviceData"
                :columns="serviceTableColumns"
                :summary-method="handleSummary"
                show-summary
              ></Table>
            </div>
          </template>
        </TabPane>
      </Tabs>
    </div>
  </div>
</template>
<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule, ParseDateForMonth, GetNextMonth, GetDateStr } from '@/utils/dateFormat'
import { formatReconcilliationStatus } from '@/utils/tagStatus'
import { listContractUseExecuteDetail, listContractServiceExecuteDetail } from '@/api/statement/contractExecuteFee'
import {
  checkContractDetail,
  getContractSignUseFee,
  getContractSignServiceFee
} from '@/api/scp/contract'

export default {
  props: {
    contractId: {
      type: Number
    }
  },
  data () {
    return {
      detailInfo: {},
      data: [],
      signUseFee: [],
      column: [
        {
          title: '单品名称',
          key: 'productName'
        },
        {
          title: '档期',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '资源数量',
          render: (h, params) => {
            return h('div', [h('p', params.row.signNormalQuantity)])
          }
        },
        {
          title: '媒体发布费刊例',
          key: 'normalUsePrice',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.normalUsePrice)
              )
            ])
          }
        },
        {
          title: '媒体发布费签约额',
          key: 'signNormalPrice',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signNormalPrice)
              )
            ])
          }
        }
      ],
      signServiceFee: [],
      serviceColumn: [
        {
          title: '名称',
          key: 'serviceItemName'
        },
        {
          title: '签约数量',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.signServiceQuantity + params.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '媒体服务费刊例',
          key: 'price',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.price) + '/' + params.row.serviceQuantityName
              )
            ])
          }
        },
        {
          title: '媒体服务费签约额',
          key: 'signServiceAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.signServiceAmount)
              )
            ])
          }
        }
      ],
      type: '2',
      useKeyword: '',
      serviceKeyword: '',
      useStartDate: null,
      useEndDate: null,
      serviceStartDate: null,
      serviceEndDate: null,

      useFeeGroupType: '4',
      serviceFeeGroupType: '4',
      tableColumnArray: {
        contractCode: {
          title: '合同编号',
          key: 'contractCode'
        },
        advertiserName: {
          title: '客户',
          key: 'advertiserName'
        },
        brandName: {
          title: '品牌',
          key: 'brandName'
        },
        executeDate: {
          title: '执行时间',
          key: 'executeDate',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {
                },
                data.row.executeDate || data.row.executeMonth
              )
            ])
          }
        },
        executeSchedule: {
          title: '执行周期',
          key: 'executeSchedule',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {
                },
                data.row.startDate + '至' + data.row.endDate
              )
            ])
          }
        },
        productName: {
          title: '产品',
          key: 'productName'
        },
        skuName: {
          title: '单品',
          key: 'skuName',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                {
                },
                data.row.productSkuName || data.row.skuName
              )
            ])
          }
        },

        stationName: {
          title: '站点',
          key: 'stationName'
        },

        resourceCode: {
          title: '资源',
          key: 'resourceCode'
        },

        executeAmount: {
          title: '执行金额',
          key: 'executeAmount',
          minWidth: 60,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.executeAmount)
              )
            ])
          }
        },
        statusName: {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          align: 'center',
          render: (h, data) => {
            return formatReconcilliationStatus(
              h,
              data.row.confirmStatus + '',
              data.row.confirmStatusName
            )
          }
        }
      },
      useData: [],
      serviceData: [],
      serviceTableColumns: [],
      useTableColumns: []
    }
  },
  created () {
    this.initPageData()
    this.initContractFee()
  },
  methods: {
    initPageData () {
      checkContractDetail({ contractId: this.contractId }).then((res) => {
        this.detailInfo = res
        // this.contract.newVersionId = this.versionId
        // this.contract.firstParty = res.customerInfoBean
        // this.contract.payments = res.paymentBeanList.map(item => {
        //   item.money = item.amount
        //   return item
        // })
        // this.contract.companyId = this.$store.getters.token.userInfo.companyId
        // this.contract.publisherId = this.$store.getters.token.userInfo.publisherId
        // this.contract.id = this.id
        // this.params = this.contract
        // this.component = 'basic'
      })
    },
    // 获合同费用
    initContractFee () {
      getContractSignUseFee({ contractId: this.contractId }).then((res) => {
        this.signUseFee = res
      })
      getContractSignServiceFee({ contractId: this.contractId }).then((res) => {
        this.signServiceFee = res
      })

      this.initUseColumns()
      this.initServiceColumns()
    },

    // 数据求汇总
    handleSummary ({ columns, data }) {
      const needSummarykey = [
        'signNormalPrice',
        'normalUsePrice',
        'signServiceAmount',
        'executeAmount'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    initUseColumns () {
      const that = this
      that.useTableColumns = [
        that.tableColumnArray.contractCode,
        that.tableColumnArray.advertiserName,
        that.tableColumnArray.brandName,
        that.tableColumnArray.executeSchedule
      ]
      switch (that.useFeeGroupType) {
        case '1':
          that.useTableColumns.push(that.tableColumnArray.productName)
          that.useTableColumns.push(that.tableColumnArray.skuName)
          that.useTableColumns.push(that.tableColumnArray.stationName)
          that.useTableColumns.push(that.tableColumnArray.resourceCode)
          break
        case '2':
          that.useTableColumns.push(that.tableColumnArray.productName)
          that.useTableColumns.push(that.tableColumnArray.skuName)
          break
        case '3':
          that.useTableColumns.push(that.tableColumnArray.productName)
          break
        case '4':
        default:
      }
      that.useTableColumns.push(that.tableColumnArray.executeAmount)
      that.useTableColumns.push(that.tableColumnArray.statusName)

      this.initUseExecuteDetail()
    },
    initServiceColumns () {
      const that = this
      that.serviceTableColumns = [
        that.tableColumnArray.contractCode,
        that.tableColumnArray.advertiserName,
        that.tableColumnArray.brandName,
        that.tableColumnArray.executeDate
      ]
      switch (that.serviceFeeGroupType) {
        case '1':
          that.serviceTableColumns.push(that.tableColumnArray.productName)
          that.serviceTableColumns.push(that.tableColumnArray.skuName)
          that.serviceTableColumns.push(that.tableColumnArray.stationName)
          that.serviceTableColumns.push(that.tableColumnArray.resourceCode)
          break
        case '2':
          break
        case '3':
          break
        case '4':
        default:
      }
      that.serviceTableColumns.push(that.tableColumnArray.executeAmount)
      this.initServiceExecuteDetail()
    },

    initUseExecuteDetail () {
      const that = this

      const query = {
        contractId: that.contractId,
        pageNumber: 1,
        pageSize: 10000,
        type: that.useFeeGroupType,
        keyWord: that.useKeyword
      }
      if (that.useStartDate) {
        query.startDate = ParseDateForMonth(that.useStartDate, '-') + '-01'
      }
      if (that.useEndDate) {
        query.endDate = GetDateStr(GetNextMonth(that.useEndDate), 0)
      }
      listContractUseExecuteDetail(query).then(res => {
        that.useData = res.list
      })
    },

    initServiceExecuteDetail () {
      const that = this
      const query = {
        contractId: that.contractId,
        pageNumber: 1,
        pageSize: 10000,
        type: that.serviceFeeGroupType,
        keyWord: that.serviceKeyword
      }
      if (that.serviceStartDate) {
        query.startDate = ParseDateForMonth(that.serviceStartDate, '-') + '-01'
      }
      if (that.serviceEndDate) {
        query.endDate = GetDateStr(GetNextMonth(that.serviceEndDate), 0)
      }
      listContractServiceExecuteDetail(query).then(res => {
        that.serviceData = res.list
      })
    }
  },
  computed: {},
  watch: {
    contractId () {
      this.initPageData()
      this.initContractFee()
    },
    useFeeGroupType () {
      this.initUseColumns()
    },
    serviceFeeGroupType () {
      this.initServiceColumns()
    }
  }
}
</script>
