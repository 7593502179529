<template>
  <div>
    <Upload
      v-if="allowUpload"
      ref="upload"
      :with-credentials="true"
      :action="action"
      :headers="uploadHeaders"
      :data="data"
      :show-upload-list="false"
      :format="format"
      :max-size="maxSize"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleExceedMaxsize"
      :on-success="successUpload"
      multiple
      type="drag"
    >
    <div style="padding: 20px 0">
          <Icon
            type="ios-cloud-upload"
            size="52"
            style="color: #3399ff"
          ></Icon>
          <p>点击或拖拽文件到这里进行上传</p>
    </div>
      <!-- <slot name="upload_btn">
        <div>
          <a >添加文件并上传</a>
        </div>
      </slot> -->

    </Upload>

    <div class="file-container" >
      <div v-if="uploadList.length>0">
        <h4 class="text-green p-t-10">最新上传文件</h4>
        <Row
          v-for="(file,i) in uploadList"
          :key="i"
        >
          <template v-if="file.status==='finished'">
            <i-col span="6">
              <Icon
                v-if="file.response.mimeType.startsWith('image')"
                type="md-images"
                class="m-l-10"
                size="38"
              />
              <img
                src="../../assets/images/WinWordLogoSmall.scale-180.png"
                height="50px"
                v-else-if="isMSWord(file.response)"
              >
              <img
                src="../../assets/images/ExcelLogoSmall.scale-180.png"
                height="50px"
                v-else-if="isMSExcel(file.response)"
              >
              <Tag
                color="blue"
                v-else
              >暂时不提供预览</Tag>
            </i-col>
            <i-col span="10">
              {{file.name}}
            </i-col>
            <i-col span="3">
              {{file.response.length}} kb
            </i-col>
            <i-col span="5">

              <a
                href="javascript:void(0)"
                class="m-r-5"
                @click="handlePreview(file)"
                v-if="file.response && file.response.mimeType.startsWith('image')"
              >预览</a>
              <a
                href="javascript:void(0)"
                @click="handleDownload(file.response)"
                v-else
              >下载</a>
               <a
                href="javascript:void(0)"
                class="m-l-5"
                style="color:#ef4f4f;"
                @click="handleRemove(file)"
              >删除</a>
            </i-col>
          </template>
          <template v-else>
            <i-col span="12">
              <Progress
                v-if="file.showProgress"
                :percent="file.percentage"
              ></Progress>
              <span v-else>{{file.name}}</span>
            </i-col>
            <i-col span="12">
              <i-button
                icon="ios-trash"
                class="m-l-5"
                type="error"
                @click="handleRemove(file)"
              >删除</i-button>
            </i-col>
          </template>

        </Row>
      </div>

      <div >
        <Divider dashed>已有文件</Divider>
        <i-table
          stripe
          :data="existsFile"
          :columns="fileColumns"
        ></i-table>
      </div>

    </div>
    <!-- 上传描述，备注信息 -->
    <div class="tips m-l-10" v-if="allowUpload">
      <ul>
        <li>您只能上传后缀名为 {{format.toString()}} 格式的文件 </li>
        <li>文件大小不能超过 {{ maxSize/ 1024 }} MB</li>
      </ul>
    </div>
    <!-- 图片预览 -->
    <Modal
      title="查看大图"
      v-model="visible"
      width="800"
    >
      <img
        :src="formatImgUrl(fileUrl, 'fileUploadModalImg')"
        id="fileUploadModalImg"
        v-if="visible"
        style="width: 100%"
      >
    </Modal>
  </div>
</template>

<script>
import { deleteFiles, getFileList } from '@/api/sys/file'
import { downloadFileRequest, downloadImgRequest } from '@/utils/download'
import { getStorage } from '@/utils/storage'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    relateId: {
      type: [Number, String],
      required: true
    },
    allowUpload: {
      type: Boolean,
      default: true
    },
    format: {
      type: Array,
      default () {
        return ['img', 'jpg', 'png', 'jpeg']
      }
    },
    accept: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 20480
    }
  },
  data () {
    return {
      existsFile: [],
      data: { type: this.type, relateId: this.relateId },
      action: process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/file/uploadfile',
      uploadList: [],
      fileUrl: '',
      visible: false,
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      fileColumns: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 32 },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('../../assets/images/WinWordLogoSmall.scale-180.png'), height: '50px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('../../assets/images/ExcelLogoSmall.scale-180.png'), height: '50px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        { title: '上传时间', key: 'updateTime' },
        {
          title: '操作',
          render: (h, data) => {
            let preview
            if (data.row.mimeType.startsWith('image')) {
              preview = h('a',
                {
                  style: { 'margin-left': '5px' },
                  on: {
                    click: () => {
                      this.handlePreview(data.row)
                    }
                  }
                }, '预览')
            }

            return h('div', [
              preview, h('a', {
                style: { 'margin-left': '5px' },
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              }, '下载'),

              h('a', {
                style: {
                  color: '#ef4f4f',
                  'margin-left': '5px'
                },
                on: {
                  click: () => {
                    this.handleRemove(data.row)
                  }
                }
              }, '删除')
            ])
          }
        }
      ]
    }
  },
  methods: {
    getFileUrl (file) {
      return process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/file/downloadfile?fileId=' + file.id
    },
    handleExceedMaxsize (file) {
      this.$Notice.warning({
        title: '已经超过了文件大小限制',
        desc: '文件  ' + file.name + ' 过大，不能超过' + this.maxSize + 'Kb'
      })
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件类型不正确',
        desc: '文件  ' + file.name + ' 的类型只能是' + this.format.toString()
      })
    },
    handleRemove (file) {
      const id = JSON.stringify([file.id || file.response.id])
      deleteFiles({ fileIds: id }).then(res => {
        if (this.existsFile.find(x => x.id === file.id)) {
          this.existsFile.splice(this.existsFile.indexOf(this.existsFile.find(x => x.id === file.id)), 1)
        } else if (this.uploadList.includes(file)) {
          this.uploadList.splice(this.uploadList.indexOf(file), 1)
        }
      })
    },
    handlePreview (file) {
      this.fileUrl = process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/file/downloadfile?fileId=' +
        (file.id || file.response.id)
      this.visible = true
    },
    getExistsFiles () {
      const that = this
      getFileList(that.data).then(res => {
        that.existsFile = res.map(file => {
          file.name = file.fileName
          return file
        })
      })
    },
    formatImgUrl (url, imgElementId) {
      return downloadImgRequest(url, imgElementId)
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/file/downloadfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    },
    successUpload () {
      this.getExistsFiles()
    }
  },
  created () {
    this.getExistsFiles()
  },
  mounted () {
    if (this.$refs.upload && this.$refs.upload.fileList.length) {
      this.uploadList = this.$refs.upload.fileList
    }
  },
  watch: {
    uploadList: function () {
      const files = this.uploadList.map(x => x.response).concat(this.existsFile)
      this.$emit('on-uploaded', files)
    },
    existsFile: function () {
      const files = this.uploadList.map(x => x.response).concat(this.existsFile)
      this.$emit('on-uploaded', files)
    }

  }
}
</script>

<style scoped>
.upload-action {
  width: 150px;
  height: 150px;
}
.file-container {
  min-height: 100px;
  padding-bottom: 50px;
  border: 1rem;
}
.tips {
  border: 5px;
  padding: 5px;
}
.tips p {
  font-size: large;
  font-weight: bold;
}
.tip li {
  padding-block-start: 1rem;
  font-size: initial;
  font-weight: normal;
  font-family: "Times New Roman", Times, serif;
}
.ivu-row {
  padding-top: 10px;
}
</style>
